<!-- 退款退货详情 -->
<template>
  <div id="service_detail">
    <div class="service_detail">
      <div class="tips">
        <h1 v-if="refundReturn.seller_state == '待审核'">已提交申请</h1>
        <h1
          v-else-if="
            refundReturn.return_type == 2 &&
              refundReturn.goods_state_v == 1 &&
              refundReturn.seller_state != '待审核'
          "
        >
          需要您将货物打包发往指定地点
        </h1>
        <!-- <h1 v-if='refundReturn.seller_state == "待审核" '>已提交申请</h1> -->
        <h1 v-else-if="refundReturn.seller_state == '不同意'">不同意</h1>

        <h1 v-else>
          {{ refundReturn.admin_state || refundReturn.seller_state }}
        </h1>
        <template>
          <p v-if="refundReturn.admin_state == '已完成'">
            您的售后申请已经通过！感谢您对十九匠的支持。
          </p>

          <p v-else>请耐心等候，如有新进度我们会在微信公众号通知您</p>
        </template>
      </div>
      <!-- <productList class='product'></productList>
            <productList class='product'></productList> -->

      <div class="info">
        <div class="__info_li flex-container">
          <span @click="doCopy(refundReturn.refund_sn)">服务单号</span>
          <p @click="doCopy(refundReturn.refund_sn)">
            {{ refundReturn.refund_sn }}
          </p>
          <i @click="doCopy(refundReturn.refund_sn)">复制</i>
        </div>
        <div class="__info_li flex-container">
          <span @click="doCopy(refundReturn.order_sn)">订单编号</span>
          <p @click="doCopy(refundReturn.order_sn)">
            {{ refundReturn.order_sn }}
          </p>
          <i @click="doCopy(refundReturn.order_sn)">复制</i>
        </div>
        <div class="__info_li flex-container">
          <span>申请时间</span>
          <p>{{ refundReturn.add_time }}</p>
        </div>
        <div class="__info_li flex-container">
          <span>服务类型</span>
          <!-- <p v-if='return_type'>退货</p> -->
          <!-- <p>{{$route.query.refund_type='2'?'退款':'退货'}}</p> -->
          <p>{{ $route.query.refund_type == 1 ? "退款" : "退货" }}</p>
        </div>
        <div class="__info_li flex-container">
          <span>退款原因</span>
          <p>{{ refundReturn.buyer_message }}</p>
        </div>
      </div>

      <template
        v-if="refundReturn.express_name || refundReturn.return_type == 2"
      >
        <div class="info">
          <div class="__info_li flex-container">
            <span
              @click="
                doCopy('广州市番禺区沙头街道大平村大平工业区23号一楼(顺丰后面)')
              "
              >退货地址</span
            >
            <p
              @click="
                doCopy('广州市番禺区沙头街道大平村大平工业区23号一楼(顺丰后面)')
              "
            >
              广州市番禺区沙头街道大平村大平工业区23号一楼(顺丰后面)
            </p>
            <i
              @click="
                doCopy('广州市番禺区沙头街道大平村大平工业区23号一楼(顺丰后面)')
              "
              >复制</i
            >
          </div>
          <div class="__info_li flex-container">
            <span @click="doCopy('十九匠/19927734210')">联系方式</span>
            <p @click="doCopy('十九匠/19927734210')">十九匠/19927734210</p>
            <i @click="doCopy('十九匠/19927734210')">复制</i>
          </div>
        </div>
      </template>

      <div class="info" v-if="refundReturn.express_name">
        <div class="__info_li flex-container">
          <span @click="doCopy(refundReturn.invoice_no)">快递编号</span>
          <p @click="doCopy(refundReturn.invoice_no)">
            {{ refundReturn.invoice_no }}
          </p>
          <i @click="doCopy(refundReturn.invoice_no)">复制</i>
        </div>
        <div class="__info_li flex-container">
          <span>快递服务</span>
          <p>{{ refundReturn.express_name }}</p>
        </div>
      </div>

      <div
        class="reason_input"
        v-if="
          refundReturn.return_type == 2 &&
            refundReturn.goods_state_v == 1 &&
            refundReturn.seller_state != '待审核'
        "
      >
        <div class="reason_list flex-container">
          <span class="flex1">快递服务</span>
          <b class="c" @click="showPicker = true">{{ thisColumns }}</b>
        </div>
        <div class="reason_list flex-container">
          <span class="flex1">快递单号</span>
          <input
            type="text"
            name=""
            v-model="kd_id"
            placeholder="请输入快递单号"
            maxlength="25"
          />
        </div>

        <van-popup v-model="showPicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            value-key="express_name"
            @cancel="showPicker = false"
            @confirm="onConfirm"
            :loading="loading"
          />
        </van-popup>

        <div class="btn" @click="saveInfo">我要发货</div>
      </div>
    </div>
  </div>
</template>
<script>
import productList from "../list/productList";

import { post } from "@get/http";
import { Toast, Picker, Dialog, Popup } from "vant";

export default {
  name: "",
  data: () => ({
    refundReturn: false,
    loading: true,
    pic_list: [],
    showPicker: false, //是否展示选择原因
    columns: [], //原因列表
    thisColumns: "请选择发货快递",
    kd_id: "",
  }),
  components: {
    productList,
    vanPicker: Picker,
    VanPopup: Popup,
  },
  methods: {
    onConfirm(value) {
      this.thisColumns = value.express_name;
      this.reason_id = value.express_id;
      this.showPicker = false;
    },
    doCopy: function(type) {
      this.$copyText(type).then(
        function(e) {
          Toast("微信:已复制");
        },
        function(e) {
          Toast("微信:复制失败");
        }
      );
    },
    async kuaidi() {
      let data = await post("/Memberreturn/get_express");
      this.loading = false;

      for (var item in data.express_list) {
        this.columns.push(data.express_list[item]);
      }
    },
    async saveInfo() {
      Toast.loading({
        message: "加载中...",
        loadingType: "spinner",
        overlay: true,
        forbidClick: true,
        duration: 0,
      });
      if (this.refundReturn.refund_id && this.reason_id && this.kd_id) {
        let data = await post("/Memberreturn/ship_post", {
          data: {
            return_id: this.refundReturn.refund_id,
            express_id: this.reason_id,
            invoice_no: this.kd_id,
          },
        });
        Toast.clear();
        Dialog.alert({
          title: "十九匠商城通知",
          message: "发货成功",
        }).then(() => {
          this.$router.push({
            name: "OrderService",
          });
        });
      } else {
        Toast("十九匠商城：请输入单号和物流");
      }
    },
  },

  async created() {
    Toast.loading({
      message: "加载中...",
      loadingType: "spinner",
      overlay: true,
      forbidClick: true,
      duration: 0,
    });
    this.kuaidi();
    let { return_info, pic_list } = await post(
      "/Memberreturn/get_return_info",
      {
        data: {
          return_id: this.$route.query?.refund_id,
        },
      }
    );
    this.refundReturn = return_info;
    this.pic_list = pic_list;
    Toast.clear();
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
#service_detail {
  height: 100%;
  background: #ebebeb;
  padding-top: rem(24);
  width: rem(750);
  margin: 0 auto;
}

.service_detail {
  .tips {
    width: rem(750);
    height: rem(180);
    background: #ffffff;
    box-sizing: border-box;
    border-radius: rem(40) rem(40) 0px 0px;
    padding: 0 rem(36);
    h1 {
      font-size: rem(30);
      font-weight: bold;
      color: #343434;
      padding-top: rem(52);
    }
    p {
      margin-top: rem(16);
      color: #b3b3b3;
      font-size: rem(22);
    }
  }
  .product {
    background: #fff;
    padding: rem(32);
    margin: rem(16) 0;
  }
  .info {
    padding: 0 rem(34);
    box-sizing: border-box;
    background: #fff;
    &:last-of-type {
      .__info_li {
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
  .__info_li {
    font-size: rem(22);

    color: #b3b3b3;
    align-items: center;
    background: #fff;
    padding: rem(18) 0;

    &:first-of-type {
      padding-top: rem(36);
    }
    &:last-of-type {
      padding-bottom: rem(36);
      border-bottom: rem(1) solid #ebebeb;
    }
    span {
      width: rem(90);
    }
    p {
      color: #343434;

      margin-left: rem(28);
    }
    i {
      display: block;
      width: rem(58);
      height: rem(28);
      background: #ebebeb;
      border-radius: rem(29);
      margin-left: rem(16);
      font-size: rem(18);
      font-weight: bold;
      text-align: center;
    }
  }
}

.reason_input {
  background: #fff;
  padding: 0 rem(32);
  margin-bottom: rem(16);
  .reason_list {
    background: url("~@image/me/icon_more.png") no-repeat center right;
    background-size: rem(12) rem(22);
    box-sizing: border-box;
    border-bottom: rem(1) solid #ebebeb;
    span,
    b {
      height: rem(130);
      line-height: rem(130);
    }
    span {
      font-size: rem(26);

      color: #808080;
    }
    .c {
      color: #b3b3b3;
      font-size: rem(29);
    }
    b,
    input {
      font-size: rem(29);

      color: #343434;
      margin-right: rem(28);
      border: none;
      text-align: right;
    }
    input {
      background: none;
    }
    input::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #b3b3b3;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #b3b3b3;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #b3b3b3;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #b3b3b3;
    }
  }
  .service_textarea {
    width: rem(686);
    height: rem(330);
    margin: rem(48) auto rem(32);
    position: relative;
    textarea {
      width: rem(686);
      height: rem(330);
      padding: rem(26) rem(32);
      box-sizing: border-box;
      color: #343434;
      font-size: rem(26);

      border: rem(1) solid #ebebeb;
      border-radius: rem(8);
      reize: none;
    }
    .bg {
      background: url("~@image/order/address_edit.png") no-repeat rem(20)
        rem(32);
      background-size: rem(25);
      text-indent: rem(20);
    }
    textarea::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #b3b3b3;
    }
    textarea:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #b3b3b3;
    }
    textarea::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #b3b3b3;
    }
    textarea:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #b3b3b3;
    }
    span {
      position: absolute;
      bottom: rem(32);
      right: rem(32);
      font-size: rem(22);
      color: #b3b3b3;
    }
  }
}

.btn {
  position: fixed;
  bottom: rem(32);
  left: 0;
  right: 0;
  margin: 0 auto;
  width: rem(640);
  height: rem(88);
  background: #ffe5e5;
  border-radius: rem(44);
  // font-size: rem(32);
  font-size: rem(32);
  font-weight: bold;
  // font-family: 'pfb';
  color: #ff4747;
  text-align: center;
  line-height: rem(88);
}
</style>
